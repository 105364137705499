.chainCol {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-top: 2rem;
}

.chainNav {
    align-content: center;
    text-align: center;
    margin: 10px;
    /* margin-bottom: 50px; */
}

.chainLink {
    margin-left: 20px;
    margin-right: 20px;
}

.chain {
    /* 23335F */
    /* width: 160px; */
    /* height: 400px; */
    background: var(--color-background-section);
    /* border-radius: 10px; */
    border-radius: 1rem;
    margin-bottom: 0.7rem;
    padding: 0.5em;
    min-height: 200px;
    /* display: flex; */
}

.chain:hover {
    background-color: var(--color-background-title);
    /* background-color: #000F; */
}

.chainBody {
    font-size: 0.9rem;
}

.chainDesc {
    font-size: 0.9rem;
    quotes: '"' '"';
    font-style: italic;
}

.chainStat {
    font-size: 1rem;
}

/* .chainStat table tbody td {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
} */

.chainLogo {
    padding-right: 5px;
    margin-right: 5px;
}

.chainTitle {
    width: 100%;
    /* height: 60px; */
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-bottom: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-items: center; */
}

.chainLabel {
    padding: 0.1rem 0.1rem;
    margin: 0.1rem;
    min-width: 100%;
    font-size: 1.2rem;
    color: var(--color-text);
    display: flex;
    align-items: flex-start;
}

.textLabel {
    max-width: 60%;
}

.statLabelTip {
    text-align: right;
    color: var(--color-text);
    margin: 0px 5px;
    padding-bottom: 2px;
    flex: 1;
    display: flex;
    align-items: center;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: flex-start;
}

.statValue {
    padding: 0.2rem 0.2rem;
    margin: 0.2rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--color-text);
}

.statDesc {
    font-size: 0.9rem;
    color: var(--color-text);
    /* height: 70px; */
}


@media (min-width: 768px) { 
    .statValue {
        font-size: 1.4em;
    }

    .textLabel {
        max-width: 80%;
    }

    .chainTitle {
        font-size: 1.4rem;
        flex-direction: column;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

    .chainCol {
        padding-right: 5rem;
    }
}

.chainMedia {
    text-align: right;
    color: var(--color-text);
    margin: 0px 5px;
    padding-bottom: 2px;
    flex: 1;
    display: flex;
    align-items: center;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: flex-start;
}


.inactive { 
    /* position: relative; 
    top: 120; 
    right: 120; 
    z-index:999;  */
    color: var(--color-smart-primary-bright);
}
