.descSection {
    /* 23335F */
    /* width: 160px; */
    min-height: 8rem;
    /* height: 200px; */
    background: var(--color-background-section);
    border-radius: 10px;
    border-radius: 0.5rem;
    margin-bottom: 0.7rem;
    padding: 0.5em;
    /* display: flex; */
}


.descSectionTitle {
    padding: 0.1rem 0.1rem;
    margin: 0.1rem;
    min-width: 100%;
    font-size: 1.2rem;
    /* display: flex;
    align-items: flex-start; */
    text-align: center;
}


.descSectionContent {
    padding: 0.2rem 0.2rem;
    margin: 0.2rem;
    font-size: 1rem;
    color: var(--color-text);
}


.descSectionNav {
    padding: 0.1rem 0.1rem;
    margin: 0.1rem;
    min-width: 100%;
    font-size: 1rem;
    text-align: center;
}



/* @media (min-width: 768px) { 
    .descSection {
        height: 120px;
    }
}
   */