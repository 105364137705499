.delegateContainer {
    width: 30%;
    text-align: right;
}

.linkBorder {
    border: 1px solid var(--color-text);
    border-radius: 0.5rem;
    padding: 3px 3px;
    margin: 4px 4px;
    white-space: nowrap;
}
