.homeMain {
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
}

.homeDetails {
    font-size: 1.1rem;
    text-align: center;
    padding-top: 1rem;
    font-weight: 300;
}

