:root {
  /* used to color the stat / card analytics for mirror */
  --color-mirror-surface :#17141a;

  /* SmarteStake Overall Branding */
  /* --color-text: white;
  --color-text-gray: #6d738c;
  --color-background: #212024;
  --color-smart-primary : #faa84c;
  --color-smart-primary-bright: #ffc107;
  --color-smart-main-surface: #0f1011;
  --color-smart-main-surface--accent: #1b191b;
  --color-gradient-background-dark :#252525;
  --color-gradient-background-light :#828282; */
  --color-smart-primary : #faa84c;
  --color-smart-primary-bright: #ffc107;
  --color-smart-main-surface: #14365D;
  --color-smart-main-surface--accent: #1b191b;
  --color-gradient-background-dark :#252525;
  --color-gradient-background-light :#828282; 
  /*
  brown shades
  --color-text: #34421E;
  --color-background: white;
  --color-background-title: #C19434;
  --color-background-section: #F1F1EF;
  --color-text-gray: #B999BE; */
  
  /* blue */
  --color-text: white;
  --color-text-gray: #B999BE;
  --color-background: #14365D;
  --color-background-title: #162d47; /* #102033; */
  --color-background-section: #2F5C8F;
/*
  --color-background-title: #d85c27;
Mauve: #A96762
Deep Blue: #2F5C8F
Mandarin: #d85c27
Lavender: #b999be
Night Moon: #14365d
*/

  /* --color-smart-primary : #faa84c;
  --color-smart-primary-bright: #ffc107;
  --color-smart-main-surface: #0f1011;
  --color-smart-main-surface--accent: #1b191b;
  --color-gradient-background-dark :#252525;
  --color-gradient-background-light :#828282; */
}


body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  background-color: var(--color-background);
  color: var(--color-text);
}
  /* background-color: #121a2f; */
  /* color: white; */
  /* display: flex; */

th {
  font-weight: normal;
}

.containerLayout {
  /* flex-direction: row;
  width: 100%;
  align-items: center;  
  justify-content: space-evenly;
  display: flex; */
  padding-right: 0rem;
  padding-left: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

#page-wrap {
  padding: 8px 12px;

  @media (min-width: 768px) { 
    padding: 20px 30px;
  }
}

.statRowLayout {
  /* width: 100%; */
  padding-right: 0rem;
  padding-left: 0rem;
  margin-left: 0rem;
  margin-right: 0rem; 
}

.statColLayout {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  margin-left: 0rem;
  margin-right: 0rem; 
}

.infoTooltip {
  /* background-color: #2a3b69!important;*/
  font-size: 1rem!important; 
  /* font-weight: 600; */
  /* background-color: #151e36!important; */
  background-color: var(--color-text)!important;
  color: black!important;
  padding: 1em;
}

.chartRow {
  margin: 0;
}

.chartGridContainer {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
  grid-template-rows: auto auto;
  gap: 20px 20px;
  grid-template-areas:
    "."
    ".";
}

/* IF layout issues happen with charts, look here */
.chartBg {
  /* 23335F */
  box-sizing: border-box;
  /* background-color: var(--color-mirror-surface); */
  background: var(--color-background-section);  
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  margin-top: 2px;
	margin-bottom: 2px;
  padding: 0.2em;
  font-size: 0.9em;
}

.page-link {
  background-color: var(--color-mirror-surface);
  color: var(--color-text);
  border: none;
}

.page-item.active .page-link {
  background-color: var(--color-text-gray);
}

@media (min-width: 900px) { 
  .chartGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
    grid-template-areas:
      ". .";
  }
  .chartBg {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 1em;
  }
}

.headerWarning {
  background-color: red;
  padding: 0.5rem;
}

.black-a {
  color: #000;
  text-decoration: underline;
}

.black-a:hover {
   color: #00F
}


.animLink {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: None;
  /* border: 1px solid var(--color-text);
  border-radius: 50%; */
  cursor:pointer;
  overflow: hidden;
  outline:none;
  margin-right: 0.5rem;
  color: var(--color-text);
  transition: transform 0.2s; border-color: 0.2s; color: 0.2s; box-shadow: 0.2s;
  outline: none !important;
  margin: 5px
}

.animLink:hover {
  color: var(--color-smart-primary-bright) !important;
  border-color: #f1ff5c;
  /* color: var(--color-smart-primary); */
  transform: translateY(-0.15em);
  cursor: pointer;
}

.animLinkSelected {
  color: var(--color-smart-primary);
}

.animLinkVisual {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: None;
  /* border: 1px solid var(--color-text);
  border-radius: 50%; */
  margin-right: 0.5rem;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: var(--color-text);
  transition: transform 0.2s; border-color: 0.2s; color: 0.2s; box-shadow: 0.2s;
  outline: none !important;
  text-decoration:underline;
}

.animLinkVisual:hover {
  color: var(--color-smart-primary-bright) !important;
  border-color: #f1ff5c;
  color: var(--color-smart-primary);
  transform: translateY(-0.15em);
  cursor: pointer;
}

.animLinkTable {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: None;
  /* border: 1px solid var(--color-text);
  border-radius: 50%; */
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: var(--color-text);
  transition: transform 0.2s; border-color: 0.2s; color: 0.2s; box-shadow: 0.2s;
  outline: none !important;
  margin: 0px
}


.MuiToggleButtonGroup-root {
  background-color: var(--color-mirror-surface);
  color: var(--color-text)!important;
  border: 0 !important;
  min-width: 30px;
  outline: 0 !important;
}

.MuiToggleButton-root {
  background-color: var(--color-mirror-surface);
  color: var(--color-text)!important;
  border: 0 !important;
  min-width: 30px;
  outline: 0 !important;
  min-width: 90px;
}

.NetworkSwitch-selected-1 {
  border: 0 !important;
  outline: 0 !important;
}

/*.MuiToggleButton-root.Mui-selected {
  background-color: purple;
} */

.notification {
  /* background-color: var(--color-smart-main-surface--accent); */
  padding: 1em;
  color: var(--color-text);
  border-radius: 0.2rem;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.success {
  padding: 1em;
  color: var(--color-smart-primary);
  border-radius: 0.2rem;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.subHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5px;
  background-color: var(--color-background-title);
}


.subHeader__group {
  /* display: flex;
  flex-direction: column-reverse;
  align-content: flex-start;
  justify-content: flex-start; */
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; /* or other value that fits your layout */
  align-items: center;
}

@media (min-width: 900px) { 
  .subHeader__group {
    flex-direction: row;
    align-content: space-between;
  }
}

.lastUpdate {
  font-weight: 200;
  margin: 4px 0;
  font-size: 14px;
}

a {
  color: var(--color-smart-primary)
}

a:hover {
  color: var(--color-smart-primary-bright);
  text-decoration: none;
}

.regularA {
  color: var(--color-text)
}

.regularA:hover {
  color: var(--color-smart-primary-bright);
  text-decoration: none;
}

.mediaCustom {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  color: var(--color-text);
}

.mediaCustomImg {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  color: var(--color-text);
}

.mediaCustomImg:hover {
  color: var(--color-smart-primary-bright);
  text-decoration: none;
}

.view-tag {
  background-color: var(--color-smart-main-surface);
  text-decoration: underline;
  border-radius: 5px;
  border: None!important;
  color: var(--color-text);
  display: inline-block;
  font-size: 1rem;
	margin: 5px 5px;
  padding: 5px 5px;
}

.breadcrumb {
  background-color: var(--color-smart-main-surface);
}

.pWithText {
  padding-left: 2rem;
  padding-right: 2rem;
}

.buttonWithText {
  float: right;
  margin-left: 10px;
  vertical-align: baseline;
  @media (min-width: 768px) { 
    margin-left: 50px;
  }
}

.subtext {
  font-size: 0.7rem;
  margin-left: 0.5rem;
}

.logoStyle {
  border-radius: 50% 50%;
  vertical-align: bottom;
  margin-bottom: 0.1em;
}

.logoStyleWithName {
  border-radius: 50% 50%;
  vertical-align: center;
  margin-bottom: 0.1em;
}

.beta {
  vertical-align: super;
  font-size: 0.6rem;
}

.tourColumnSettings, .tourFavorite, .tourHelp, .tourValName {
  font-size: inherit;
}

/* .tourFavorite {
  font-size: inherit;
} */

.ssTour {
  background-color: var(--color-background)!important;
}

/* .watermark {
  position: relative;
}

.watermark:after {
  content: "";
  display: block;
  pointer-events: none;
  width: 210px;
  height: 150px;
  position: absolute;
  top: 0px;
  right: 30px;      
  background-image: url('images/smartstake.png');
  background-size: 64px 64px;
  background-position: 30px 30px;
  background-repeat: no-repeat;
  opacity: 0.5;
} */

.imageMargin {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.filter-label .form-control {
  height: 1.5rem;
  font-family: 'Roboto', sans-serif;
  padding: 0.25rem 0.25rem;
  background-color: transparent;
  color: var(--color-text);
  /* border: 2px solid var(--color-background); */
  /* border: 1px solid var(--color-background); */
}

.filter-label {
  margin: 0 0;
  padding: 0 0;
}

.dropdown-item a {
  display: block;
}

.valBadge {
  margin-left: 0.1em;
  margin-right: 0.1em;
  border-radius: 50%;
  padding: 0.1em;
  color: var(--color-text);
}

.valBadgeBorder {
  padding: 0.1em;
  margin: 0.02em;
  /* border: 1px dotted var(--color-text); */
}

.positiveGrowth {
  color: #49be25;
}

.negativeGrowth {
  color: red;
}

.animLinkMenu {
  margin: 0px;
  border: none;
}


.chains {
  margin: 0rem;
}

@media (min-width: 900px) { 
  body {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

/* .oddSection {
  background-color: var(--color-text);
  color: var(--color-background);
} */

hr {
  width:50%;
  margin:0 auto;
  border-color: var(--color-text);
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 1rem;
}

.sortOrder {
  max-width: 15rem!important;
  background-color: var(--color-background)!important;
  color: var(--color-text);
  margin-right: 0.5rem;
  @media (max-width: 768px) { 
    max-width: 10rem!important;
  }

}

input, textarea {
  background-color: var(--color-background)!important;
  color: var(--color-text)!important;
  border: 1px solid;
  border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  height: 2rem;
}

select {
  background-color: var(--color-background)!important;
  color: var(--color-text)!important;
  height: 2rem;
}
