.ssModal {
    /* background-color: var(--color-background)!important; */
    color: var(--color-text)!important;
    /* background-color: "black"; */
}

.ssModal .modal-content {
    background-color: var(--color-background)!important;
    color: var(--color-text)!important;
    /* background-color: "black"; */
}

.close {
    color: #fff;
}

.close:hover {
    color: #aaa;
}

.dashboard {
    /* 23335F */
    /* width: 160px; */
    height: 50px;
    background: var(--color-background-section);
    /* background: var(--color-mirror-surface); */
    border-radius: 10px;
    border-radius: 0.5rem;
    margin-bottom: 0.7rem;
    padding: 0.5em;
    /* display: flex; */
}

.dashboardValue {
    padding: 0.2rem 0.2rem;
    margin: 0.2rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-text);
}