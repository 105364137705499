.headerRow {
    padding: 0px 0px;
    margin: 0px 0px;
    width: 100%;
}

.headerTitle {
    font-size: 1.2rem;
    font-weight: 700;
    width: 60%;
    display: inline-block;
}

.headerActions {
    flex: 1;
    display: inline-block;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .headerActions {
        text-align: right;
    }
}

