.compFooter {
  margin-top: 10px;
  width: 100%;
  font-weight: normal;
  font-size: 1.2em;
  padding: 2em;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  /* flex-direction: column-reverse; */
  justify-content: center;
  align-items: center;
  background-color: var(--color-smart-main-surface);

  @media (min-width: 768px) { 
    flex-direction: column;
    justify-content: space-between;
  }
} 


.poweredBySmartStake {
  flex: 1 1 auto;;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
  font-size: 1.2em;
  margin-top: 20px;

  @media (min-width: 768px) { 
    margin-top: 0;
  }
}

.versionInfo {
  flex: 1 1 auto;;
  text-align: center;
  display: inline-block;
  font-size: 0.6rem;
}

.compFooter a {
  color: var(--color-smart-primary)
}

.compFooter a:hover {
  color: var(--color-smart-primary-bright);
  text-decoration: none;
}

/* .middleFooter {
  width: 4%;
  text-align: left;
  display: inline-block;
} */

.socialMedias {
  font-size: 1.2em;
  text-align: right;
  margin-right: 10px;
}

.notification a {
  color: var(--color-smart-primary);
  text-decoration: underline;
  padding-left: 5px;
  padding-right: 5px;
}

.notification a:hover {
  color: var(--color-smart-primary-bright);
  /* text-decoration: none; */
}

